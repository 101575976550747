.container-new.ai.larger.clip {
  height: 100dvh;
  min-height: 860px;
  position: relative;
  overflow: hidden;
}

.container-new.ai.larger {
  justify-content: flex-start;
  height: 200dvh;
}
.container-new.ai {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  height: auto;
  min-height: 90px;
  position: static;
  overflow: hidden;
}
.container-new {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 96px 24px;
  display: flex;
}

.ai-container-box {
  border-radius: 24px;
  /* width: 1440px; */
  height: 100%;
  min-height: 1000px;
  margin-top: 0;
  padding: 0;
  position: relative;
  top: auto;
  overflow: hidden;
}

.ai-container {
  border: 1px solid #e1e5f0;
  background-color: black;
  color: black;
  border-radius: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.scanlines-section {
  background-color: black;
  width: 100%;
  height: 100%;
}

.grid-wrapper {
  perspective: 1000px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  min-height: 560px;
  display: flex;
  overflow: hidden;
}

.grid-15 {
  transform-style: preserve-3d;
  width: 100vw;
  height: 200vh;
  position: relative;
  overflow: hidden;
  transform: translateY(-50vh) rotateX(69deg) rotateY(0) rotate(0);
}

.grid__mask {
  z-index: 2;
  transform-style: preserve-3d;
  background-image: radial-gradient(circle, #0000, #000 69%);
  width: 100%;
  height: 200vh;
  position: absolute;
  transform: translateZ(2000px);
}

.grid__vertical {
  z-index: 0;
  justify-content: space-around;
  width: 100%;
  height: 200vh;
  display: flex;
  position: absolute;
  top: 0%;
}

.vertical-line {
  background-color: #63b852;
  width: 2px;
  height: 100%;
  box-shadow: 0 0 10px #3ae94c;
}

.grid__horizontal-wrapper {
  z-index: 1;
  width: 100%;
  height: 400vh;
  position: absolute;
  top: 0%;
  transform-style: preserve-3d;
  animation: verticalScroll 3s linear infinite;
}
@keyframes verticalScroll {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -100vh, 0px);
  }
}

.grid__horizontal {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  display: flex;
}

.horizontal-line-2 {
  background-color: #acff00;
  width: 100%;
  height: 2px;
  box-shadow: 0 0 10px #3ae94c;
}

.ai-container-text {
  height: 100%;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 640px;
  /* margin-top: 64px; */
  display: flex;
  justify-content: center;
  position: absolute;
  box-shadow: 0 20px 5px #0003;
}

.section-heading-new.white {
  color: white;
}

.section-heading-new {
  color: #0f1013;
  text-align: center;
  letter-spacing: -2.5px;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 72px;
  font-weight: 700;
  line-height: 110%;
}

.section-paragraph.white {
  color: white;
}

.section-paragraph {
  color: #777c8a;
  text-align: center;
  letter-spacing: -0.7px;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
}

.section-paragraph.aigenerate {
  color: #acff00;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-top: 32px;
  font-size: 14px;
  font-weight: 700;
}

.ai-tools {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.app-name-label-copy {
  border: 1px solid #acff00;
  background-color: black;
  color: #acff00;
  letter-spacing: -0.03em;
  border-radius: 80px;
  padding: 14px 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  display: inline-block;
  box-shadow: 0 0 #0000, 0 1px 0.4px #00000008, 0 2px 0.8px #0000000a,
    0 3px 1.6px #0000000b, 0 5px 2.9px #0000000c, 0 9px 5.3px #0000000c,
    0 15px 10.4px #0000000d, 0 31px 22.8px #0000000e;
}

@media (max-width: 768px) {
  .section-heading-new {
    font-size: 28px;
  }

  .section-paragraph.white {
    color: white;
  }

  .section-paragraph {
    font-size: 3.5vw;
  }

  .ai-tools {
    display: flex;
    flex-direction: column;
  }

  .grid__mask,
  .grid__vertical,
  .grid__horizontal-wrapper {
    display: none;
  }
}
