@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.marquee {
  animation: scroll-left 25s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.slider-container-lib {
  align-items: flex-end;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  position: relative;
}

.slider-lib {
  z-index: 9;
  border-style: solid solid none;
  border-width: 10px;
  border-color: black black var(--2023-darkgrey);
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  width: 100%;
  min-width: 354px;
  max-width: 354px;
  height: 100%;
  min-height: 640px;
  max-height: 640px;
  position: relative;
  top: 0;
  overflow: visible;
}

.left-lib-blank {
  z-index: 8;
  position: relative;
  bottom: 30px;
  right: -25px;
}

.w-slider {
  text-align: center;
  clear: both;
  -webkit-tap-highlight-color: #0000;
  /* tap-highlight-color: #0000; */
  background: #ddd;
  height: 300px;
  position: relative;
}

.right-lib-blank {
  z-index: 10;
  position: relative;
  bottom: 90px;
  left: -130px;
}

.mask {
  border: 8px solid #000;
  border-radius: 15px;
}

.w-slider-mask {
  z-index: 1;
  white-space: nowrap;
  display: block;
  position: relative;
  left: 0;
  right: 0;
  overflow: hidden;
}

.campare-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr minmax(0, 0.25fr) 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  align-items: stretch;
  display: grid;
}

.vs-text {
  z-index: 20;
  /* color: var(--2023-grey-4); */
  text-align: center;
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e0e0e0;
  /* border-radius: 100px; */
  margin-top: 48px;
  margin-bottom: 0;
  /* padding: 22px; */
  /* font-size: 1.5vw; */
  font-weight: 700;
  /* line-height: 100%; */
  position: relative;
  right: 40px;
  box-shadow: 0 0 #0000, 0 2px 0.2px #00000006, 0 4px 0.4px #00000007,
    0 6px 0.7px #00000008, 0 8px 0.9px #00000009, 0 11px 1.2px #00000009,
    0 14px 1.6px #0000000a, 0 17px 2.1px #0000000a, 0 20px 2.7px #0000000a,
    0 25px 3.5px #0000000b, 0 30px 4.4px #0000000b, 0 37px 5.8px #0000000b,
    0 46px 7.5px #0000000b, 0 57px 9.9px #0000000b, 0 72px 13.2px #0000000c,
    0 94px 17.8px #0000000c, 0 125px 24.8px #0000000c, 0 175px 36px #0000000d,
    0 271px 57.8px #0000000e;
}

.imageContainer {
  will-change: transform, filter;
}

.zoom-text {
  color: #dcedff;
  text-shadow: 2px 2px 22px #33a0ff;
}

.zoom-content {
  background-color: #000;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.example-5 {
  position: relative;
}

.example-5 svg {
  position: absolute;
}

.example-5 .line {
  stroke-dasharray: 260;
  stroke-width: 4px;
  fill: transparent;
  stroke: white;
  animation: svgAnimation 2.5s linear infinite;
  opacity: 0.4;
}

@keyframes svgAnimation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 500;
  }
}

::-webkit-scrollbar {
  display: none; /* Might be enough */
  background: transparent !important;
  visibility: hidden;
  width: 0px;
}

@media (max-width: 992px) {
  .campare-grid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    row-gap: 10px;
  }
}
